import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import wellSyncLogo from "../../../assets/images/wellSyncLogo.svg";
import menuJson from "../../../data/menu.json";
import Line from "../../microComponent/Line";
import { useNavigate , useLocation} from "react-router-dom";
import { Link } from "@mui/material";


interface MenuItem {
  id: number;
  label: string;
  link: string;
  active: boolean;
}

function Header() {
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const [menu, setMenu] = useState<MenuItem[]>(menuJson);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>, page: any) => {
    const myMenu = menu.map((item: any) => {
      if (item.link === page.link) {
        item.active = true;
      } else {
        item.active = false;
      }
      return item;
    });
    setMenu(myMenu);
    setTimeout(() => navigate(page.link), 300);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    if(location?.pathname) {
      const myMenu = menu.map((item: any) => {
        if (item.link === location.pathname) {
          item.active = true;
        } else {
          item.active = false;
        }
        return item;
      });
      setMenu(myMenu);
    }
  }, [location?.pathname])

  return (
    <AppBar position="sticky" className="!bg-theme-color-1 py-4">
      <Container maxWidth="xl">
        <Toolbar disableGutters className="justify-between">
          <Link href="/">
          <img src={wellSyncLogo} alt="brand Logo" className="xs:h-12 sm:h-16" />
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton className="!ml-auto text-gray-1 !shadow-none" size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {menu.map((page) => (
                <MenuItem key={page.id} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" className="!text-black">
                    {page.label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} className="ml-0 justify-evenly">
            {menu.map((page) => (
              <Button
                key={page.id}
                onClick={(e) => handleOpenUserMenu(e, page)}
                sx={{ m: 2, color: "white", display: "block" }}
                className="!text-black !capitalize !text-[1rem] !font-normal !tracking-wider !shadow-none"
                size="large"
              >
                {page.label}
                {page.active && <Line />}
              </Button>
            ))}
          </Box>
          <Button variant="contained" className="!bg-theme-green-2 sm:!block xs:!hidden !shadow-none" size="large" onClick={() => navigate("/contactus")}>
            <Typography className="text-radium-green capitalize" fontWeight="500">
              Contact Us
            </Typography>
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
