import React, { useEffect, useState } from "react";
import { Container, Grid, Link } from "@mui/material";
import useScrollToTop from "../../hooks/useScrollToTop";
import { useQuery } from "@apollo/client";
import { Content } from "../../apollo/Content";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

export default function Team() {
  const { data } = useQuery(Content);
  useScrollToTop();
  const navigate = useNavigate();
  const clickHandle = (path: any) => {
    navigate(path);
  };
  const [footer, setFooter] = useState<any>([]);
  const [footericon, setFootericon] = useState<any>({});
  const [footerterm, setFooterterm] = useState<any>([]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setFooter(data.footers.data[0].attributes);
      setFootericon(data.footericons.data[0].attributes);
      setFooterterm(data.footerterms.data[0].attributes);
    }
  }, [data]);
  
  const { footermenu } = footer;

  return (
    <>
      <Grid className={"bg-wheel-color overflow-hidden sm:mt-0 sm:py-16"}>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item sm={8}>
              {footer.logo && (
                <div className="brandlogo mb-10">
                  <img src={"http://localhost:1337" + footer.logo.data.attributes.url} className="mx-2" />
                </div>
              )}
              {footermenu && (
                <div className="w-full ">
                  {footermenu.map((item: any, i: number) => (
                    <Link href={"/" + item.link} underline="hover" key={i} className="!text-radium-green !mx-4">
                      {item.title}
                    </Link>
                  ))}
                </div>
              )}
            </Grid>
            <Grid item sm={4} className="flex items-center justify-end">
              {!_.isEmpty(footericon) &&
                Object.keys(footericon).map((key: any, index: number) => {
                  if (footericon[key].data) {
                    return (
                      <Link href={footericon[key].data.attributes.caption} underline="hover" key={index}>
                        <img src={"http://localhost:1337" + footericon[key].data.attributes.url} className="mx-2 h-6 w-6" key={index} />
                      </Link>
                    );
                  }
                })}
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid className={"bg-radium-green overflow-hidden !py-3 footer"}>
        <Container maxWidth="xl">
          <Grid container>
              <Grid item xs={12} md={3} className={"text-left"}>
                {footerterm.label1}
              </Grid>
              <Grid item xs={12} md={2} className={"text-left"}>
                <Link href="/privacypolicy" underline="hover" className="!text-wheel-color">{footerterm.label2}</Link>
              </Grid>
              <Grid item xs={12} md={2} className={"text-left"}>
              <Link href="/Termscondition" underline="hover" className="!text-wheel-color">{footerterm.label3}</Link>
                {/* {footerterm.label3} */}
              </Grid>
              <Grid item xs={12} md={2} className={"text-left flex"}>
                {footerterm.label4}
                {footerterm.logo && <img src={"http://localhost:1337" + footerterm.logo.data.attributes.url} className="mx-2 h-6 w-6" />}
              </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  );
}
