// Router.js
import React, {Suspense} from 'react';
import { Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';

const Home = loadable(() => import(/* webpackChunkName: "home" */ '../Pages/Home'));
const Solutions = loadable(() => import(/* webpackChunkName: "solutions" */ '../Pages/Solutions'));
const WhoWeAre = loadable(() => import(/* webpackChunkName: "whoweare" */ '../Pages/WhoWeAre'));
const Team = loadable(() => import(/* webpackChunkName: "team" */ '../Pages/Team'));
const Member = loadable(() => import(/* webpackChunkName: "team" */ '../Pages/Member'));
const Careers = loadable(() => import(/* webpackChunkName: "careers" */ '../Pages/Careers'));
const ContactUs = loadable(() => import(/* webpackChunkName: "contactus" */ '../Pages/ContactUs'));
const Thankyou = loadable(() => import(/* webpackChunkName: "Thankyou" */ '../Pages/Thankyou'));
const Telemedicine = loadable(() => import(/* webpackChunkName: "telemedicine" */ '../Pages/Telemedicine'));
const PrivacyPolicy = loadable(() => import(/* webpackChunkName: "privacypolicy" */ '../Pages/PrivacyPolicy'));
const Termscondition = loadable(() => import(/* webpackChunkName: "privacypolicy" */ '../Pages/Termscondition'));

const AppRouter = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/whoweare" element={<WhoWeAre />} />
        <Route path="/team" element={<Team />} />
        <Route path="/member/:id" element={<Member />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/telemedicine" element={<Telemedicine />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/terms&Conditions" element={<Termscondition />} />
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
