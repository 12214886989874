import { useEffect } from 'react';

function useScrollToTop(id?: any) {
  useEffect(() => {
    // Scroll to the top of the page when the component using this hook mounts
    console.log("running window scroll");
    window.scrollTo(0, 0);
  }, [id]);
}

export default useScrollToTop;
