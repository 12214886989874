import { gql } from "@apollo/client";

export const Content = gql`
query {
  banner1S (pagination: { limit: 100 }) {
    data {
      attributes {
        legend
        subtitle
        title
        link
        button2
        image {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
  members (pagination: { limit: 100 }){
    data{
      attributes{
        name
        about
        designation
        teamid
        background{
          data{
            attributes{url}
          }
        }
        person{
          data{
            attributes{url}
          }
        }
      }
    }
  }
  careersforms (pagination: { limit: 100 }){
    data{
      attributes{
        title
        formjson
      }
    }
  }
  advisorymembers{
    data{
      attributes{
        name
        designation
        readMote
        teamid
        profileImg{
          data{
            attributes{
              url
            }
          }
        }
      }
    }
  }
  teamMembers (pagination: { limit: 100 }) {
    data{
      attributes{
        name
        designation
        readMote
        teamid
        profileImg{
          data{
            attributes{
              url
            }
          }
        }
      }
    }
  }
  privacypolicies{
    data{
      attributes{
        title
        subtitle
        listtype
        content
      }
    }
  }
  termsconditions{
    data{
      attributes{
        title
        subtitle
        listtype
        content
      }
    }
  }
  formjsons (pagination: { limit: 100 }) {
    data{
      attributes{
        legend
        title
        subtitle
        telephone
      }
    }
  }
  imgnobuttons (pagination: { limit: 100 }) {
    data {
      attributes {
        title
        subtitle
        image {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
  imgbgclrs (pagination: { limit: 100 })  {
    data {
      attributes {
        title
        subtitle
        button
        image {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
  imgnoclrs (pagination: { limit: 100 }) {
    data {
      attributes {
        title
        subtitle
        button
        imagenoclr {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
  footers (pagination: { limit: 100 }){
    data{
      attributes{
        footermenu
        logo{
          data{
            attributes{
              url
            }
          }
        }
      }
    }
  }
  footericons (pagination: { limit: 100 }){
    data{
      attributes{
        X{
          data{
            attributes{
              url
              caption
            }
          }
        }
        insta{
          data{
            attributes{
              url
              caption
            }
          }
        }
        linkd{
          data{
            attributes{
              url
              caption
            }
          }
        }
        fb{
          data{
            attributes{
              url
              caption
            }
          }
        }
      }
    }
  }
  advisories{
    data{
      attributes{
        name
        about
        designation
        teamid
        background{
          data{
            attributes{url}
          }
        }
        person{
          data{
            attributes{url}
          }
        }
      }
    }
  }
  advisoryWithTitles{
    data{
      attributes{
        title
      }
    }
  }
  positions{
    data{
      attributes{
        Job
      }
    }
  }
  footerterms (pagination: { limit: 100 }) {
    data{
      attributes{
        label1
        label2
        label3
        label4
        logo{
          data{
            attributes{
              url
            }
          }
        }
      }
    }
  }
}`;